import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import presence, { Presence, Value } from '../model/presence';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import { AUTHORITIES } from '../store/modules/auth';
import moment from 'moment';

const logger = new Logger('backend.Presence');
export interface BackendPresence {
  getPresence(id: string): AxiosPromise<Presence>;
  getAllPresences(): AxiosPromise<Value>;
  getPresences: (
    searchParams: SearchParams,
    roles?: AUTHORITIES[],
    currentDateTime?: string,
    locationId?: string,
    applicationUserId?: string,
    isOnline?: boolean,
    trainerId?: string,
    fromTillDate?: {
      fromDate: string | undefined;
      tillDate: string | undefined;
    }
  ) => AxiosPromise<Value>;
  deletePresence(id: string): AxiosPromise;
  updatePresence(Presence: Presence): AxiosPromise<any>;
  enterPresence(enterPresencePayload: {
    applicationUserId?: string;
    attendeeId?: string;
    locationId: string;
    text?: string;
  }): AxiosPromise<any>;
  leavePresence(enterPresencePayload: {
    applicationUserId?: string;
    attendeeId?: string;
  }): AxiosPromise<any>;
  leaveMultiplePresencesByAttendances(
    attendanceIds: string[]
  ): AxiosPromise<any>;

  lastState(payload: {
    applicationUserId?: string;
    attendeeId?: string;
  }): AxiosPromise<Presence>;
  countOnline(
    roles?: AUTHORITIES[],
    locationId?: string,
    trainerId?: string,
    applicationUserId?: string
  ): AxiosPromise<number>;
}

export const defaultBackendPresence: BackendPresence = {
  getPresence(id: string): AxiosPromise<Presence> {
    let url = `${URLS.presenceOdata}/${id}`;
    return instance.get<Presence>(url);
  },
  getAllPresences(): AxiosPromise<Value> {
    let url = `${URLS.presence}/GetAll`;
    logger.log(`getAllReferences${url}`);
    return instance.get<Value>(url);
  },
  getPresences(
    searchParams: SearchParams,
    roles?: AUTHORITIES[],
    currentDateTime?: string,
    locationId?: string,
    applicationUserId?: string,
    isOnline?: boolean,
    trainerId?: string,
    fromTillDate?: {
      fromDate: string | undefined;
      tillDate: string | undefined;
    }
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (roles) {
      var odfbInner = ODataFilterBuilder('or');
      roles.forEach((x) => odfbInner.eq(CONST.Role, x));
      odfb.and(odfbInner);
    }

    if (currentDateTime) {
      const momentCurrentDateTime = moment(currentDateTime);
      odfb.ge(
        CONST.DateEnter,
        momentCurrentDateTime.startOf('day').toISOString(),
        false
      );
      odfb.lt(
        CONST.DateEnter,
        momentCurrentDateTime.endOf('day').toISOString(),
        false
      );
    }

    if (fromTillDate?.fromDate) {
      odfb.ge(CONST.DateEnter, fromTillDate.fromDate, false);
    }

    if (fromTillDate?.tillDate) {
      const tillDate = moment(fromTillDate.tillDate);
      odfb.le(CONST.DateEnter, tillDate.endOf('day').toISOString(), false);
    }

    if (locationId != undefined) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    if (applicationUserId != undefined) {
      odfb.eq(CONST.ApplicationUserId, applicationUserId, false);
    }

    if (isOnline != undefined) {
      odfb.eq(CONST.IsOnline, isOnline);
    }

    let baseUrl =
      trainerId != undefined
        ? `${URLS.presenceOdata}/GetByTrainerId(trainerId=${trainerId})`
        : `${URLS.presenceOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getPresences${url}`);
    return instance.get<Value>(url);
  },

  deletePresence(id: string): AxiosPromise {
    logger.debug('deletePresence');
    return instance.delete(`${URLS.presence}/${id}`);
  },
  updatePresence(Presence: Presence): AxiosPromise<any> {
    logger.debug('updatePresence');
    if (Presence.id != undefined) {
      return instance.put<Presence>(
        `${URLS.presence}`,
        presence.toAPI(Presence)
      );
    } else {
      return instance.post<Presence>(
        `${URLS.presence}`,
        presence.toAPI(Presence)
      );
    }
  },

  enterPresence(payload: {
    applicationUserId?: string;
    attendeeId?: string;
    locationId: string;
    text?: string;
  }): AxiosPromise<any> {
    logger.debug('enterPresence');
    return instance.post(`${URLS.presence}/Enter`, payload);
  },

  leavePresence(payload: {
    applicationUserId?: string;
    attendeeId?: string;
  }): AxiosPromise<any> {
    logger.debug('leavePresence');
    return instance.post(`${URLS.presence}/Leave`, payload);
  },

  leaveMultiplePresencesByAttendances(
    attendanceIds: string[]
  ): AxiosPromise<any> {
    const payload = { AttendanceIds: attendanceIds };
    return instance.post(`${URLS.presence}/LeaveMulipleByAttendances`, payload);
  },

  lastState(payload: {
    applicationUserId?: string;
    attendeeId?: string;
  }): AxiosPromise<Presence> {
    logger.debug('lastState');
    return instance.post(`${URLS.presence}/LastState`, payload);
  },

  countOnline(
    roles?: AUTHORITIES[],
    locationId?: string,
    trainerId?: string,
    applicationUserId?: string
  ): AxiosPromise<number> {
    return instance.post<number>(`${URLS.presence}/CountOnline`, {
      LocationId: locationId,
      Roles: roles,
      TrainerId: trainerId,
      ApplicationUserId: applicationUserId,
    });
  },
};
